import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import InsuccifientFollowers from "../components/insufficient_followers/insufficient_followers";
import Header from "../components/header/header";

function InsuccifientFollowersPage() {
  return (
    <ChakraProvider>
      <Header />
      <InsuccifientFollowers />
    </ChakraProvider>
  );
}

export default InsuccifientFollowersPage;

import { Box, Center } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import BackgroundGradient from "../../images/background_gradient_bottom.svg";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import { navigate } from "gatsby";

interface InsuccifientFollowersProps {}

const InsuccifientFollowers: React.FC<InsuccifientFollowersProps> = (props) => {
  return (
    <Box
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      backgroundRepeat="no-repeat"
      style={{
        background: `url(${BackgroundGradient})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "bottom",
        backgroundColor: COLORS_PRIMARY.BACKGROUND,
      }}
      height="100vh"
      alignItems="center"
    >
      <Center
        textAlign="center"
        padding="4rem"
        flexDirection="column"
        height="100%"
      >
        <OpaliteTextTitle marginBottom="1rem">We're sorry.</OpaliteTextTitle>
        <OpaliteTextStandard marginBottom="1rem">
          Your account does not meet our current minimum follower count. Please
          apply again soon!
        </OpaliteTextStandard>
        <OpaliteButtonStandard onClick={() => navigate("/all_nft")}>
          Shop NFTs
        </OpaliteButtonStandard>
      </Center>
    </Box>
  );
};

export default InsuccifientFollowers;
